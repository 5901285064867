var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"justify-center mt-1"},[_c('v-row',{staticClass:"pt-8",attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"justify":"center","align":"center"}},[_c('h1',[_vm._t("header")],2),_c('v-divider',{staticClass:"ml-3",staticStyle:{"padding":"1px !important","border":"none !important"},attrs:{"color":"#a81e2d","width":"50px"}}),_vm._t("subHeader")],2)],1),_c('Splide',{staticClass:"pt-8 text-center",class:_vm.$vuetify.breakpoint.smAndDown? (_vm.$vuetify.breakpoint.xs?'':'px-2'):'px-6',attrs:{"options":{
      type: 'loop',
      perPage: _vm.$vuetify.breakpoint.mdAndDown ? (_vm.$vuetify.breakpoint.xs? 1:2) : 2,
      perMove: 1,
      arrows: false,
      rewind: true,
      gap:'50px',
      autoplay: true,
      pauseOnHover: false,
    },"data-splide-interval":"1"}},[_vm._t("content")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }