<template>

  <v-container fluid class="pa-0 ma-0">

 <Header/>
    <v-row no-gutters>
      <UnderLinedHeader
        class="white mt-4"
        :class="!$vuetify.breakpoint.smAndDown ? 'px-16' : 'px-2'"
      >
        Complete, Knowledge-Driven Solutions
        <br />
        to Help Your Practice Thrive
      </UnderLinedHeader>
    </v-row>
    <v-row no-gutters
      justify="center"
      align="center"
      class="mt-8"
      :class="!$vuetify.breakpoint.smAndDown ? 'px-6' : 'px-1'"
    >
      <p
        class="artice-text text-center"
        :class="!$vuetify.breakpoint.mdAndDown ? 'px-8' : 'px-4'"
      >
        3545 Consulting – Global provides comprehensive, technology solutions, tailored to empower law firms to work smarter and more efficiently. Our dedicated team of experts get to know each client and their personnel to gain a thorough understanding of how their law firm operates. We listen to your needs and ask the necessary questions to find customized solutions within your budget that will improve processes and help you effectively run your business.
      </p>
    </v-row>
    

    <!-- <products /> -->

    <Services/>
    <Endorsements />
   
  </v-container>
</template>

<script>
import ScrollDownBtn from "@/components/landing/ScrollDownBtn.vue";
// import Products from "@/components/landing/Products.vue";
import UnderLinedHeader from "@/components/global/compnentsMixins/UnderLinedHeader.vue";
import Header from "@/components/landing/Header.vue";
import Services from "@/components/landing/Services.vue";
import Endorsements from "@/components/landing/Endorsements.vue";
import SupportedProducts from "@/components/landing/SupportedProducts";

import DataMixin from "@/components/global/mixins/DataMixin";
import Vue from 'vue';
import VueMeta from 'vue-meta';
Vue.use(VueMeta);

export default {
  name: 'App',    
    
    metaInfo: {
    title: "Home - 3545 Consulting",
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { property:'og:locale', content:"en_US"},
      { property:'og:type', content: "website"},
      { property:'og:title', content: "Home - 3545 Consulting"},
      { property:'og:url', content:"https://3545consulting.com/"},
      { property:'og:site_name', content:"3545 Consulting"},
      { property:'article:publisher', content:"https://www.facebook.com/pages/category/Business-Consultant/3545-Consulting-Global-211671325562596/"},
      { name:'twitter:card', content:"summary_large_image"},
      { name:'twitter:label1', content:"Est. reading time"},
      { name:'twitter:data1', content:"3 minutes"},
      { name:'geo.placename', content:"Houston"},
      { name:'geo.position', content:"294351.9;952534.4"},
    ]
  }, 
  /* metaInfo: {
    title: '354535453545consulting',
    titleTemplate: '354535453545consulting',
    htmlAttrs: {
      lang: 'en-US'
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: '354535453545consulting' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
  }, */
  mixins: [DataMixin],
  components: {
    UnderLinedHeader,
    Header,
    Services,
    //Products,
    ScrollDownBtn,
    //Carousel,
    Endorsements,
    SupportedProducts,
  },
};
</script>
 
