<template>
  <v-card flat>
    <PageHeader> About 3545 </PageHeader>
    <v-toolbar color="White" flat prominent>
      <template v-slot:extension>
        <v-tabs v-model="tabs" centered color="#a81e2d" show-arrows>
          <v-tab v-for="item in items" :key="item">
            <v-card-text>{{ item }}</v-card-text>
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <v-tabs-items v-model="tabs" align="center" class="Black">
      <v-tab-item v-for="(item, index) in TabsData" :key="index">
        <v-card
          flat
          class="pt-4 pb-8"
          :class="!$vuetify.breakpoint.smAndDown ? 'px-14' : 'px-3'"
        >
          <v-card-text v-html="item"></v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <!-- <v-container style="background-color: #f8f9f9">
      <UnderLinedHeader> Specific Services That We Offer </UnderLinedHeader>

      <v-row class="Black">
        <v-col cols="12" s="12" xs="12" lg="6" xl="6" md="6">
          <hoveredList :items="fr_list" />
        </v-col>
        <v-col cols="12" s="12" xs="12" lg="6" xl="6" md="6">
          <hoveredList :items="sc_list" />
        </v-col>
      </v-row>
    </v-container> -->

    <!-- <v-row class="contactDiv my-16" align="center">
      <v-col
        class="vcolRight pl-16"
        cols="12"
        s="12"
        xs="12"
        lg="6"
        xl="6"
        md="6"
      >
        <span class="ml-16"> Get in touch with us today.</span>
      </v-col>

      <v-col
        align="end"
        class="vcolLeft pr-16"
        cols="12"
        s="12"
        xs="12"
        lg="6"
        xl="6"
        md="6"
      >
        <v-btn class="mr-16" outlined color="white"> CONTACT US</v-btn>
      </v-col>
    </v-row> -->
  </v-card>
</template>

<style scoped lang="scss">
.contactDiv {
  font-size: 26px;
  color: white;
  font-family: Tahoma, "Helvetica Neue", Helvetica, Arial, sans-serif;
  height: 150px;
  background: #a81e2d;
}

.v-toolbar .v-card__text {
  font-weight: bold !important;
}
.v-toolbar .v-tabs-items {
  font-size: 0.9em !important;
  font-weight: bold !important;
}
.v-tabs-items .v-card__text {
  font-family: $primaryFontFamily !important;
  font-size: 1em !important;
}
.v-card__text {
  font-family: $primaryFontFamily !important;
  font-size: 1em !important;
}

ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  columns: 2;
  width: 100%;
  float: left;
  padding: 10px;
  margin: 10px;

  li {
    width: 50%;
    padding: 10px 30px 10px 5px !important;
    font-family: $primaryFontFamily;
    font-size: 1em !important;

    &::marker {
      color: $pred !important;
      font-size: 1.3rem;
    }
  }
}
</style>

<script>
import HoveredList from "@/components/global/compnentsMixins/HoveredList.vue";
import PageHeader from "@/components/global/compnentsMixins/PageHeader.vue";
import UnderLinedHeader from "@/components/global/compnentsMixins/UnderLinedHeader.vue";
export default {
  metaInfo: {
    title: "About Us",
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { property:"og:locale", content:"en_US"},
      { property:"og:type", content: "article"},
      { property:"og:title", content: "About Us - 3545 Consulting"},
      { property:"og:url", content:"https://3545consulting.com/About/"},
      { property:"og:site_name", content:"3545 Consulting"},
      { property:"article:publisher", content:"https://www.facebook.com/pages/category/Business-Consultant/3545-Consulting-Global-211671325562596/"},
      { name:"twitter:card", content:"summary_large_image"},
      { name:"twitter:label1", content:"Est. reading time"},
      { name:"twitter:data1", content:"1 minutes"},
      { name:"geo.placename", content:"Houston"},
      { name:"geo.position", content:"294351.9;952534.4"},
    ]
  },
  components: { HoveredList, PageHeader, UnderLinedHeader },
  data() {
    return {
      items: ["WHO WE ARE", "AREA OF EXPERTISE"],
      tabs: null,
      TabsData: [
        `We have a strong foundation of committed professionals working
            together in a team environment to offer complete and effective
            solutions to our clients. Our varied and talented team members cover
            a wide range of specialties, including, but not limited to: Business
            Analysis and Strategy, Technology Analysis and Strategy, Workflow
            Analysis, Change Management, Talent Management and Development,
            Project Administration and Management, Software Implementation, Data
            Migration and Conversion, Software Training, Practice Management and
            Case Management, Billing and Accounting Assistance, Document
            Management, Document Automation and Development Solutions, Managed
            Services, Private Cloud and Hosting Services, Information Technology
            (IT) and Networking Implementation and Support (both Onsite and
            Remote), Database Repairs, Business Analytics and Metrics, Custom
            Reporting and Analysis. <br />
            <br />
            Every one of our partners is an official member of the Microsoft Partner
            Network and Value Added Resellers for a large number of software
            solutions. Additionally, our experienced team of consultants and
            associates hold individual and corporate certifications in a variety
            of computer and data technologies and software applications.`,

        `3545 specializes in implementing hardware, software, and service
            solutions for a variety of businesses, including: law firms, legal
            entities, educational institutions, and managed healthcare. Our goal
            is to evaluate our clients’ needs and budget constraints in order to
            identify appropriate hardware, services, or off-the-shelf software
            packages, and then to install, configure, and customize these
            assets, as well as migrate data into the new environment, train the
            end-users and systems support teams, and support and maintain the
            systems we install. Our staff, a unique collection of highly
            qualified and motivated individuals, functions as a cohesive team
            capable of addressing our clients’ needs and stands unwavering
            against even the most complex technological challenges. In addition,
            our technical consultants frequently serve as liaisons to in-house
            IT professionals, supporting and augmenting our clients’ existing IT
            departments, and ultimately providing a balanced approach to
            introduction and support of software applications and hardware
            solutions for their business.`,
      ],
      fr_list: [
        "Monitor and maintain server and workstation hardware and operating system, which may include installing operating system updates, firmware/BIOS updates.",
        "Install, maintain, and monitor asset management agents and antivirus software on servers and workstations.",
        "Remediate potentially unwanted software, ransomware, and virus infection issues",
        "Identify and resolve root causes using in-band and out-of-band, (when available), management tools",
        "Set up proactive notification and automatically notifying ticket system for server hardware failures",
        "Verify and maintain active user accounts and password lists, if appropriate",
      ],
      sc_list: [
        "Monitor and maintain scheduled backups and periodically test the backup’s integrity",
        "Set up, monitor, and maintain SQL maintenance jobs, if applicable",
        "Troubleshoot desktop application errors and act as technical liaisons to third parties who troubleshoot applications, hardware, IT service errors or performance issues",
        "Install application updates and patches on servers and workstations. Upgrades and major version updates priced separately.",
        "Manage and maintain firewall and network infrastructure",
        "Perform an annual technology review",
      ],
    };
  },
};
</script>