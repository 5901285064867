<template>
  <div class="justify-center mt-1">
    <v-row class="pt-8" justify="center" align="center" no-gutters>
      <v-col justify="center" align="center" class=""> 
        <h1><slot name="header"></slot></h1>
        <v-divider
          color="#a81e2d"
          width="50px"
          class="ml-3 "
          style="padding: 1px !important; border: none !important"
        ></v-divider>
        <slot name="subHeader"></slot>
      </v-col>
    </v-row>

    <Splide
      :options="{
        type: 'loop',
        perPage: $vuetify.breakpoint.mdAndDown ? ($vuetify.breakpoint.xs? 1:2) : 2,
        perMove: 1,
        arrows: false,
        rewind: true,
        gap:'50px',
        autoplay: true,
        pauseOnHover: false,
      }"
      class="pt-8 text-center "
      :class="$vuetify.breakpoint.smAndDown? ($vuetify.breakpoint.xs?'':'px-2'):'px-6'"
      data-splide-interval="1"
    >
      <slot name="content"></slot>
    </Splide>
  </div>
</template>
<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/splide.min.css";
export default {
  components: {
    Splide,
    SplideSlide,
  },
  props: {
    items: {
      type: Array,
    },
  },
};
</script>