<template>
  <v-row>
    <v-col>
      <v-row class="mt-2" justify="center" align="center">
         <template v-for="item in this.membersMatchSearchQuery">
        <v-col
          cols="12"
          sm="6"
          xs="6"
          xl="3"
          lg="3"
          md="4"
          align="center"
          
          :key="item.name"
           v-if="!item.isAssociate"
        >
          <Member
            :name="item.name"
            :job="item.job"
            :imgName="item.imgPath"
           
          />
        </v-col>
        </template>
      </v-row>


      <v-row class="mt-2" justify="center" align="center">
        <template v-for="item in this.membersMatchSearchQuery">
          <v-col
            :key="item.name"
            cols="12"
            sm="2"
            xs="2"
            xl="2"
            lg="2"
            md="2"
            align="center"
            v-if="item.isAssociate"
          >
            <Member :name="item.name" :job="item.job" :imgName="item.imgPath" />
          </v-col>
        </template>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import Member from "@/components/team/Member.vue";

export default {
  components: { Member },

  computed: {
    membersMatchSearchQuery() {
      if (this.$store.state.SearchQuery) {
        return this.$store.state.TeamMembers.filter((member) => {
          return member.name
            .toLowerCase()
            .includes(this.$store.state.SearchQuery.toLowerCase());
        });
      }
      return this.$store.state.TeamMembers;
    },
  },
};
</script>