<template>
  <div>
    <div class="res-container ma-2">
      <div
        v-for="(item, index) in items"
        :key="index"
        :class="item.class"
        v-on="
          item.isLast == true
            ? { mouseover: rightHover, mouseout: rightOut }
            : {}"
        class="res-column bg pa-10 my-2"
        :style="[
          {
            'background-image':
              item.gradiant +
              ', url(' +
              require('@/assets/imgs/' + item.imgNmae) +
              ')',
          },
        ]"
      >
        <v-row
          justify="center"
          align="center"
          style="height: 100%"
          class="content-row"
        >
          <v-col cols="12" class="l-text-col">
            <div class="titl">{{ item.title }}</div>
            <hr class="text-left" />
          </v-col>
          <v-col cols="12" class="r-text-col">
            <div class="text">{{ item.text }}</div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          class: "left",
          title: "Business Solutions and Strategies",
          text: "Every business has areas of inefficiency.  When it comes to law firms, our team of expert consultants not only help to identify the inefficiencies but suggest intelligent innovative strategies and solutions tailored to your practice.",
          imgNmae: "Business_Solutions_and_Strategies.webp",
          gradiant:
            "linear-gradient(to right,  rgba(168,30,45, .8) 10%  ,rgba(168,30,45, .9) 80%) ",
        },
        {
          class: "middle",
          title: "Network and Technology",
          text: "We have specialists on hand with decades of experience in the legal industry to help with your complete technology requirements. From software selection, to implementation, data migration, to customization, our team of experts is dedicated to unraveling and resolving the most complex technology challenges you may face.",
          imgNmae: "Network_and_Technology.webp",
          gradiant:
            "linear-gradient(to right,  rgba(58,58,58, .5) 10%,rgba(58,58,58, .9) 88%)",
        },
        {
          isLast: true,
          class: "right",
          title: "Training and Support",
          text: "Technology investments are only as good as your ability to use them.  The right technology can help you maximize productivity and increase the bottom line.  Our trainers equip your team with the skills and knowledge they need to improve the proficiency and success of your practice.  Our support team is there to prevent down time and ensure efficiency and profitability.",
          imgNmae: "Training_Support.webp",
          gradiant:
            "linear-gradient(to right,   rgba(31,98,142, .8) 10% ,rgba(31,98,142, .9) 88%)",
        },
      ],
    };
  },
  methods: {
    rightHover: function () {
      document.querySelector(".middle").classList.add("middle-hover");
      document.querySelector(".left").classList.add("left-hover");
    },
    rightOut: function () {
      document.querySelector(".middle").classList.remove("middle-hover");
      document.querySelector(".left").classList.remove("left-hover");
    },
  },
};
</script>


<style lang="scss" scoped>
// :root {
//   --leftofmiddle: calc(100% / 3);
// }
hr {
  border-top: 10px solid weight;
  width: 100px;
  margin-top: 20px;
  margin-left: 50%;
  transform: translateX(-50%);
}
.titl {
  color: white;
  font-size: 22px;
}
.text {
  color: white;
  font-size: 24px;
}
.bg {
  // background-attachment: fixed !important;
  background-size: cover !important;

  background-position: bottom bottom !important;
}
.content-row {
  flex-direction: column !important;
}
.l-text-col,
.r-text-col {
  text-align: center;
}
@media only screen and (min-width: 800px) {
  hr{
      margin-left: 18%;
      transform: translateX(0%);
  }
  .l-text-col,
  .r-text-col {
    text-align: start;
  }
  .l-text-col {
    flex: 3;
  }
  .r-text-col {
    flex: 8;
  }
  .content-row {
    flex-direction: row !important;
  }
  .titl {
    font-size: 36px;
    margin-left: 18%;
    margin-top: -18%;
  }
  .text {
    margin-left: 18%;
  }
  .res-container {
    margin-left: 5%;
    margin-right: 5%;
    z-index: 1;
    overflow: hidden;

    margin-top: 50px;
    height: 500px;

    position: relative;
  }
  .res-column {
    overflow: hidden;
    transition: 0.8s;
    position: absolute;
    top: 0px;
    height: 500px;
    width: 100%;
  }
}

.left {
  background-color: black;
  left: 0px;
  z-index: 100;
  &:hover {
    ~ .middle {
      left: 100%;
      top: 100% !important;
    }
    ~ .right {
      left: 100%;
      top: 100% !important;
    }
  }
}
.middle {
  background-color: blue;
  // left: var(--leftofmiddle);
  left: calc(100% / 3);
  z-index: 200;
  &:hover {
    ~ .right {
      left: 100%;
    }
    left: 0%;
  }
}
.right {
  left: calc((100% / 3) * 2);
  background-color: green;
  z-index: 300;
  &:hover {
    left: 0%;
  }
}
.left-hover {
  top: 100% !important;
}
.middle-hover {
  left: 0%;
  top: 100% !important;
}
</style>
