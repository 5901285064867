export default {

    UpdateTeamMembers:(state,payload)=>{
        //console.log(payload)
        //console.log(state)
        state.TeamMembers = payload.value
        //console.log(state.cars)
    },
    UpdateSearchQuery:(state,payload)=>{
        //console.log(payload)
        //console.log(state)
        state.SearchQuery = payload.value
        //console.log(state.cars)
    },
    UpdateTeamfilters:(state,payload)=>{
        //console.log(payload)
        //console.log(state)
        state.Teamfilters = payload.value
        //console.log(state.cars)
    },
    UpdateSpecfilters:(state,payload)=>{
        //console.log(payload)
        //console.log(state)
        state.Specfilters = payload.value
        //console.log(state.cars)
    },
    UpdateSiteData:(state,payload)=>{
        //console.log(payload)
        //console.log(state)
        state.SiteData = payload.value
        //console.log(state.cars)
    }
}