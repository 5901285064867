<template>
  <section>
    <pageHeader>Case Studies</pageHeader>
    <UnderLinedHeader class="pt-6 pb-4 white"
      >3545 Consulting-Global’s Case Study Series</UnderLinedHeader
    >
    <v-row align="center" class="pt-0 pb-10 " :class="$vuetify.breakpoint.smAndDown?'px-2':'px-16'" no-gutters>
      <p class="artice-text text-center">
        In our case study series, we share the stories of clients ranging from
        specialized projects to complete technology overhauls. Learn what
        motivated each
        <b />group to make critical business investments and why 3545
        Consulting-Global was their partner of choice.
      </p>
    </v-row>
    <v-row no-gutters>
    <v-row style="justify-content: space-around" no-gutters>
      <v-col
      class="flipDiv"
        align="center"
        cols="12"
        s="12"
        xs="12"
        lg="4"
        xl="4"
        md="6"
        v-for="(item, index) in items"
        :key="index"
      >
        <a :href="item.url" target="_blank">
          <FlipCard>
            <template v-slot:front>
              <imageCard
                :imageName="item.frontImageName"
                :alt="item.frontImageName"
                parentFolder="Resourses"
                :minHeight_c="350"
                style="min-width:185px;"
              >
              </imageCard>
            </template>
            <template v-slot:back>
              <imageCard
                v-if="item.backImageName"
                :imageName="item.backImageName"
                :alt="item.backImageName"
                parentFolder="Resourses"
                :minHeight_c="350"
              ></imageCard>

              <p v-else class="flip-card-caption text-center">
                View Case Study
              </p>
              
            </template>
            
            <div >{{item.title}}</div>
          </FlipCard>
        </a>
      </v-col>
      
    </v-row>
    </v-row>
  </section>
</template>

<script>
import PageHeader from "@/components/global/compnentsMixins/PageHeader.vue";
import UnderLinedHeader from "@/components/global/compnentsMixins/UnderLinedHeader.vue";
import FlipCard from "@/components/global/compnentsMixins/FlipCard.vue";
import ImageCard from "@/components/global/ImageCard.vue";
export default {
  metaInfo: {
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { property:"og:locale", content:"en_US"},
      { property:"og:type", content: "article"},
      { property:"og:title", content: "Case Studies - 3545 Consulting"},
      { property:"og:url", content:"https://3545consulting.com/CaseStudies/"},
      { property:"og:site_name", content:"3545 Consulting"},
      { property:"article:publisher", content:"https://www.facebook.com/pages/category/Business-Consultant/3545-Consulting-Global-211671325562596/"},
      { name:"twitter:card", content:"summary_large_image"},
      { name:"twitter:label1", content:"Est. reading time"},
      { name:"twitter:data1", content:"3 minutes"},
      { name:"geo.placename", content:"Houston"},
      { name:"geo.position", content:"294351.9;952534.4"},
    ]
  },
  components: { PageHeader, UnderLinedHeader, FlipCard, ImageCard },
  data() {
    return {
      items: [
        {
          frontImageName: "Silverman-Case-Study-600px.jpeg",
          backImageName: "Silverman-Case-Brief.png",
          url: "https://f.hubspotusercontent00.net/hubfs/5825854/Client%20Success%20Stories/Case%20Brief%20Study%20SIlverman%20Thompson%20Final%20-%20ST%20Aug.%2021.pdf",
          title: "Silverman Thompson Slutkin & White, LLC",
        },
        {
          frontImageName: "Pirkey-Barber-Case-Study-600px-New.jpeg",
          backImageName: "Perky-Barber-Case-Brief-300x300.png",
          url: "https://f.hubspotusercontent00.net/hubfs/5825854/Client%20Success%20Stories/3545%20Pirkey%20Barber%20Case%20Study%20(HOU)%20FINAL%20ST%20Aug%2026.pdf",
          title: "Pirkey Barber PLLC",
        },
        {
          frontImageName: "West-Mermis-Case-Study-600px.jpeg",
          backImageName: "West-Mermis-300x300.png",
          url: "https://f.hubspotusercontent00.net/hubfs/5825854/Client%20Success%20Stories/3545_West%20Mermis_Case%20Study%20FINAL%20ST%20Aug.%2026.pdf",
          title: "West Mermis PLLC",
        },
        {
          frontImageName: "Ermer-Case-Study-600px.jpeg",
          backImageName: "",
          url: "https://secureservercdn.net/45.40.152.13/l8e.e3d.myftpupload.com/wp-content/uploads/case_studies/Case_Study-Ermer.pdf",
          title: "Ermer Law Group, PLLC",
        },
        {
          frontImageName: "PKM-Case-Study-600px.jpeg",
          backImageName: "",
          url: "https://secureservercdn.net/45.40.152.13/l8e.e3d.myftpupload.com/wp-content/uploads/case_studies/Case_Study-Plavnicky.pdf",
          title: "Plavnicky Kinzel Makowski LLP",
        },
        {
          frontImageName: "Zadeh-Case-Study-600px.jpeg",
          backImageName: "",
          url: "https://secureservercdn.net/45.40.152.13/l8e.e3d.myftpupload.com/wp-content/uploads/case_studies/Case_Study-Zadeh.pdf",
          title: "Law Office of Jim Zadeh, P.C.",
        },
        {
          frontImageName: "CYP-Case-Study-600px.jpeg",
          backImageName: "",
          url: "https://secureservercdn.net/45.40.152.13/l8e.e3d.myftpupload.com/wp-content/uploads/case_studies/Case_Study-CYP.pdf",
          title: "Chilton Yambert Porter LLP",
        },
      ],
      articles: [
        {
          title: "Silverman Thompson Slutkin & White, LLC",
          text: "More and more law firms are discovering that their office server-based technologies can no longer adequately support a range of operational functions from case management to timekeeping & billing to accounting and document management. At the same time, doing the needed complete technology overhaul may seem like a daunting task and many firms may not know where to begin or which criteria are most important in deciding on the next technology to choose and implement. The law firm of Silverman Thompson Slutkin & White found themselves in just that position. Read how the firm quickly learned that the quality of their technology implementation provider was just as important as the quality of the technology platform they were implementing and how these two teams seamlessly worked together to execute a uniquely integrated cloud solution via an innovative tech partnership that was also uniquely customized for Silverman Thompson’s practice areas.",
          imgName: "Silverman-Case-Study-600px.png",
          link: "https://f.hubspotusercontent00.net/hubfs/5825854/Client%20Success%20Stories/Case%20Brief%20Study%20SIlverman%20Thompson%20Final%20-%20ST%20Aug.%2021.pdf",
          caseStudyText: `Read the Silverman Thompson Slutkin & White, LLC Case Study and learn more about how our team can solve for the unknown in nearly any situation.`,
          say: `“3545 Consulting provided a major value-added service to the entire process. In terms of converting our data, providing training to our staff, and working with Caret Legal to customize a solution for us, 3545 Consulting hit the mark on all of these.” `,
          dashedSay: `— Andrew Slutkin, Senior Partner, Silverman Thompson Slutkin & White`,
          class: "greyBG",
          color: "#f8f9f9",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $headerFontFamily;
}
p.artice-text {
  font-family: $primaryFontFamily;
  font-size: 1em;
}
.flip-card-caption {
  background-color: $pred;
  color: white;
  padding-top: 130px;
  padding-bottom: 130px;
  margin-top: 22px;
  font-size: 20px;
}
.flipDiv{
  margin-bottom:100px;
}
</style>