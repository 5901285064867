<template>
  <v-container fluid>
    <v-row style="background-color: #3a3a3a" align="center" class="pa-16">
      <v-col align="center">
        <h1 class="px-10 white--text pt-0">Meet Our Team</h1>
      </v-col>
    </v-row>

    <v-row class="pt-16">
      <v-col align="center">
        <h4 :class="!$vuetify.breakpoint.smAndDown ? 'px-16' : 'px-2'">
          Every 3545 partner is an official member of the Microsoft Partner
          Network and Value-Added Resellers for many software solutions.
          Additionally, our experienced team of consultants and associates hold
          individual and corporate certifications in a variety of computer and
          data technologies and software applications.
        </h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <Filters />
        <Members />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        
      </v-col>
    </v-row>
  </v-container>
</template>


<style scoped>
h1 {
  font-size: 48px;
}
</style>




<script>
import Members from "@/components/team/Members.vue";
import Filters from "@/components/team/Filters.vue";

import DataMixin from "@/components/global/mixins/DataMixin";
import { mapActions } from "vuex";
export default {
  metaInfo: {
    title: "Team",
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { property:"og:locale", content:"en_US"},
      { property:"og:type", content: "article"},
      { property:"og:title", content: "Our Team - 3545 Consulting"},
      { property:"og:url", content:"https://3545consulting.com/team/"},
      { property:"og:site_name", content:"3545 Consulting"},
      { property:"article:publisher", content:"https://www.facebook.com/pages/category/Business-Consultant/3545-Consulting-Global-211671325562596/"},
      { name:"twitter:card", content:"summary_large_image"},
      { name:"twitter:label1", content:"Est. reading time"},
      { name:"twitter:data1", content:"1 minutes"},
      { name:"geo.placename", content:"Houston"},
      { name:"geo.position", content:"294351.9;952534.4"},
    ]
  },
  async created() {
    // await consulting_axios.LoadSiteContent().then(res=>{
    //  if(res.data.data) this.UpdateSiteDataAction({ value: res.data.data });;
    // })
    // console.log(JSON.parse(this.$store.state.SiteData.find((member)=>member.Name=='home')?.Content).header);
    const data = require("@/assets/team.json");
    this.UpdateTeamMembersAction({ value: data });
  },
  mounted() {
    // hide the overlay when everything has loaded
    // you could choose some other event, e.g. if you're loading
    // data asynchronously, you could wait until that process returns
    setTimeout(() => {
      this.overlay = false;
    }, 1);
  },
  methods: {
    ...mapActions(["UpdateTeamMembersAction", "UpdateSiteDataAction"]),
  },
  mixins: [DataMixin],
  components: {
    Members,
    Filters,
  },
};
</script>
 
