<template>
  <v-container
    fluid
    class="mb-0 pb-0 mt-16 pa-5-10 pa-lg-5 pa-xl-10"
    style="background-color: #051d40"
  >
      <v-snackbar v-model="snackbar" timeout="600">
      copied!

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-row
      class="py-5 mb-0"
      :justify="!$vuetify.breakpoint.smAndDown ? 'center' : 'start'"
    >
      <!-- social/contact -->
      <v-col
        cols="12"
        xl="4"
        lg="4"
        md="4"
        sm="6"
        xs="12"
        :class="!$vuetify.breakpoint.smAndDown ? 'pt-8 ' : 'pt-1'"
      >
        <v-row>
          <v-col>
            <v-img
              :class="!$vuetify.breakpoint.smAndDown ? 'ml-11' : 'ml-n2'"
              max-height="120"
              max-width="120"
              alt="3545 consulting"
              src="@/assets/imgs/3545 Consulting_Logo_white.png"
            ></v-img>
          </v-col>
        </v-row>
        <v-row :class="!$vuetify.breakpoint.smAndDown ? ' pl-14 ' : ''">
          <v-col
            cols="12"
            class="pt-4 mb-n5"
            :class="!$vuetify.breakpoint.smAndDown ? 'ml-n3' : ''"
          >
            <h4>Follow Us!</h4>
          </v-col>
          <v-col
            cols="12"
            :class="!$vuetify.breakpoint.smAndDown ? 'ml-n4' : ''"
          >
            <span class="socials">
              <v-btn
                href="https://www.linkedin.com/company/3545consulting/"
                target="_blank"
                icon
              >
                <v-img
                  max-height="35"
                  max-width="35"
                  alt="linkedin icon"
                  src="@/assets/imgs/linkedin-sociocon.png"
                ></v-img>
              </v-btn>

              <v-btn
                href="https://twitter.com/3545_Consulting"
                target="_blank"
                icon
              >
                <v-img
                  :class="!$vuetify.breakpoint.smAndDown ? 'ml-1' : ''"
                  max-height="30"
                  max-width="30"
                  alt="twitter icon"
                  src="@/assets/imgs/twitter icon.png"
                ></v-img>
              </v-btn>

              <v-btn
                href="https://www.facebook.com/3545ConsultingGlobal"
                target="_blank"
                icon
              >
                <v-img
                  max-height="30"
                  max-width="30"
                  alt="facebook icon"
                  src="@/assets/imgs/facebook icon.png"
                ></v-img>
              </v-btn>
            </span>
          </v-col>
        </v-row>
        <v-row :class="!$vuetify.breakpoint.smAndDown ? ' pl-14 ' : ''">
          <v-col
            cols="12"
            class="pt-4 mb-n4 mt-n1"
            :class="!$vuetify.breakpoint.smAndDown ? 'ml-n4' : ''"
          >
            <h4>Contact Us!</h4>
          </v-col>
          <v-col
            cols="12"
            :class="!$vuetify.breakpoint.smAndDown ? 'ml-n3' : ''"
          >
            <p
              :class="!$vuetify.breakpoint.smAndDown ? 'ml-n1 mb-1' : ''"
              class="contact"
               @click="
                () => {
                  copyToClipboard('713-789-3323');
                  this.snackbar = true;
                }"
            >
              <v-icon small class="mr-2" color="white">mdi-phone</v-icon>
              713-789-3323
            </p>
            <p
              :class="!$vuetify.breakpoint.smAndDown ? 'ml-n1' : ''"
              @click="openLink('mailto:info@3545consulting.com')"
              class="contact"
            >
              <v-icon small class="mr-2" color="white">mdi-email</v-icon>
              info@3545consulting.com
            </p>
          </v-col>
        </v-row>
      </v-col>
      <!-- addresses, contact, support -->
      <v-col
        cols="12"
        xl="4"
        lg="4"
        md="4"
        sm="6"
        xs="12"
        :class="!$vuetify.breakpoint.smAndDown ? 'pt-16' : 'pt-4'"
      >
        <v-row
          :justify="!$vuetify.breakpoint.smAndDown ? 'center' : 'start'"
          :class="!$vuetify.breakpoint.smAndDown ? 'pt-4' : ''"
        >
          <v-col :align="!$vuetify.breakpoint.smAndDown ? 'center' : 'start'">
            <!-- schedule a consultation button -->
            <span
              class="hs-cta-wrapper"
              id="hs-cta-wrapper-db8c93b3-6484-4341-8e2f-525b66f8d04f"
            >
              <span
                class="hs-cta-node hs-cta-db8c93b3-6484-4341-8e2f-525b66f8d04f"
                id="hs-cta-db8c93b3-6484-4341-8e2f-525b66f8d04f"
              ></span>
              <a
                href="https://cta-redirect.hubspot.com/cta/redirect/5825854/db8c93b3-6484-4341-8e2f-525b66f8d04f"
                target="_blank"
                rel="noopener"
              >
                <img
                  class="hs-cta-img"
                  id="hs-cta-img-db8c93b3-6484-4341-8e2f-525b66f8d04f"
                  style="border-width: 0px"
                  src="https://no-cache.hubspot.com/cta/default/5825854/db8c93b3-6484-4341-8e2f-525b66f8d04f.png"
                  alt="Schedule a Consultation "
              /></a>
            </span>
            <h3 class="mt-6 mb-2">Client Support</h3>
            <!-- open a ticket button -->
            <span
              class="hs-cta-wrapper"
              id="hs-cta-wrapper-66d46eab-7341-488c-97d6-255110a99cc9"
            >
              <span
                class="hs-cta-node hs-cta-66d46eab-7341-488c-97d6-255110a99cc9"
                id="hs-cta-66d46eab-7341-488c-97d6-255110a99cc9"
              >
              </span>
              <a
                target="_blank"
                href="https://cta-redirect.hubspot.com/cta/redirect/5825854/66d46eab-7341-488c-97d6-255110a99cc9"
              >
                <img
                  class="hs-cta-img"
                  id="hs-cta-img-66d46eab-7341-488c-97d6-255110a99cc9"
                  style="border-width: 0px"
                  src="https://no-cache.hubspot.com/cta/default/5825854/66d46eab-7341-488c-97d6-255110a99cc9.png"
                  alt="Open a Ticket"
                />
              </a>
            </span>
          </v-col>
        </v-row>
      </v-col>
      <!-- locatiom -->
      <v-col
        :align="!$vuetify.breakpoint.smAndDown ? 'center' : 'start'"
        cols="12"
        xl="4"
        lg="4"
        md="4"
        sm="6"
        xs="12"
        :class="!$vuetify.breakpoint.smAndDown ? 'pt-11' : 'pt-1'"
      >
        <h3>Location</h3>
        <p class="pt-2">
          3545 Consulting Global
          <br/>1980 Post Oak Blvd, Ste 100
          <br />Houston, TX 77056
        </p>
        <v-row>
          <v-col  justify="center">
            <v-img
              :class="!$vuetify.breakpoint.smAndDown ? 'ml-4' : ''"
              class="p-logo mt-6"
              max-width="120"
              @click="$router.push({ name: 'Landing' }).catch((err) => {})"
              alt="Diamond Partner"
              src="@/assets/imgs/diamond-partner.webp"
            ></v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col align= "right" class="pa-0">
            <v-img  :class="!$vuetify.breakpoint.smAndDown ? 'ml-4' : ''"
              class="p-logo mt-6"
              max-width="120"
              @click="$router.push({ name: 'Landing' }).catch((err) => {})"
              alt="2Mid-Market-Partner_of_the_Year"
              src="@/assets/imgs/Mid-Market-Partner_of_the_Year[89].webp">
            </v-img>
          </v-col>
          <v-col align= "left" class="pa-0">
            <v-img  :class="!$vuetify.breakpoint.smAndDown ? 'ml-4' : ''"
              class="p-logo mt-6"
              max-width="120"
              @click="$router.push({ name: 'Landing' }).catch((err) => {})"
              alt="PatternBuilder_MAX-Partner_of_the_year"
              src="@/assets/imgs/PatternBuilder_MAX-Partner_of_the_year.webp">
            </v-img>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center" no-gutters>
      <p class="text-center pb-2">
        Copyright 2024 3545 Consulting-Global. All rights reserved <br />Privacy
        Policy
      </p>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return{
      snackbar: false
    }
  }
};
</script>

<style lang="scss" scoped>
.contact {
  cursor: pointer;

}
.social:hover {
  color: #00bcd4 !important;
}
p {
  font-size: 14px;
  margin-bottom: 0;
}
* {
  color: white;
}
</style>