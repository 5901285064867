<template>
  <v-list shaped class="mt-6 transparent">
    <v-list-item
      class="itm"
      v-for="(item, i) in this.items"
      :key="i"
      align="flex-start"
    >
      <v-list-item-icon v-if="withBullet" class="pt-2 ma-0">
        <v-icon color="#a81e2d" size="36px">mdi-circle-small </v-icon>
      </v-list-item-icon>

      <v-list-item-content class="itm-text text-left" v-html="item">
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<style lang="scss" scoped>
//@import '@/styles/main.scss';
.itm {
  .itm-text {
    &:hover {
      color: $pred;
    }
  }
}
</style>

<script>
export default {
  props: {
    items: {
      type: Array,
    },
    withBullet: {
      type: Boolean,
      default:true
    },
  },
};
</script>