import Vue from 'vue'
import Vuex from 'vuex'
import actions from "@/store/actions"
import mutations from "@/store/mutations"
import getters from "@/store/getters"
import state from "@/store/state"
// import x from "@/store/plugins/x"
Vue.use(Vuex)

export default new Vuex.Store({
  // plugins: [x],
state: state,
mutations: mutations,
actions: actions,
getters: getters,
strict: process.env.NODE_ENV !== 'production'
})
