<template>
  <p class="mt-12 ml-2">{{ personalInfo }}</p>
</template>

<style scoped>
p{
    font-family: $primaryFontFamily;
    font-size: 16px;
}
</style>

<script>


export default {
  props: {
    personalInfo: {
      type: String
    },
  },
};
</script>