<template>
  <v-row align="center" justify="center" no-gutters>
    <v-col cols="12" justify="center" align="center" class="mx-0 pa-0">
      <v-img
        class="bg"
        :src="require('@/assets/imgs/' + imgNmae)"
        alt="3545consulting Home"
      >
        <!-- gradient="to left,  rgba(0, 0, 0, 0.5) ,rgba(0, 0, 0, .01) 40%" -->
        <v-row justify="end" align="center" style="height: 100%" >
          <v-col cols="6" ></v-col>
          <v-col cols="6" align="start">
            <div
              class="header"
              :class="!$vuetify.breakpoint.smAndDown ? 'px-16' : 'px-2'"
            >
              <!-- <span >{{JSON.parse(this.$store.state.SiteData.find((member)=>member.Name=='home').Content).header}}</span> -->
              <span>Leaders in Technology Solutions</span>
            </div>

            <v-btn
              @click="openLink('mailto:info@3545consulting.com')"
              rounded
              elevation="0"
              :style="[
                !$vuetify.breakpoint.smAndDown
                  ? { 'margin-left': '280px !important' }
                  : {},
              ]"
              class="contact-us-btn"
              :class="!$vuetify.breakpoint.mdAndDown ? 'ml-16 mt-10' : 'mt-4'"
              >Contact us today</v-btn
            >
          </v-col>
        </v-row>
      </v-img>
    </v-col>
  </v-row>
</template>

<script>
export default {

  data() {
    return {
      imgNmae: "3545consulting_home.webp",
    };
  },
};
</script>

<style lang="scss" scoped>
.bg {
  max-height: 470px;
}
@media only screen and (min-width: 1400px) {
  .bg {
    max-height: 500px;
  }
}
@media only screen and (min-width: 1700px) {
  .bg {
    max-height: 600px;
  }
}
@media only screen and (min-width: 1900px) {
  .bg {
    max-height: 700px;
  }
}
@media only screen and (min-width: 2600px) {
  .bg {
    max-height: 900px;
  }
}
@media only screen and (min-width: 3800px) {
  .bg {
    max-height: 1400px;
  }
}
@media only screen and (max-width: 1150px) {
  .header span {
    font-size: 20px !important;
  }
  .contact-us-btn {
    font-size: 12px !important;
    height: 25px !important;
  }
}
@media only screen and (max-width: 600px) {
  .header span {
    font-size: 16px !important;
  }
  .contact-us-btn {
    height: 20px !important;
    font-size: 10px !important;
  }
}

.header {
  span {
    letter-spacing: 2px;
    font-size: 36px;
    font-weight: 700;
    font-family: $primaryFontFamily;
    color: white;
  }
}
</style>