<template>
  <v-card flat class="mt-4" :class="!$vuetify.breakpoint.smAndDown?'ml-8':'mx-2'" >
    <v-tabs v-model="tab" align-with-title color="black" :show-arrows="$vuetify.breakpoint.smAndDown">
      <v-tabs-slider color="#a81e2d"></v-tabs-slider>

      <v-tab v-for="item in items" :key="item" :href="'#' + item">
        {{ item }}
      </v-tab>
      <v-tab-item value="Biography" class="tab-item">
        <Biography :member="memberInfo" />
      </v-tab-item>
      <v-tab-item value="Personal Info" class="tab-item">
        <PersonalInfo :personalInfo="memberInfo.personalInfo" />
      </v-tab-item>
      <v-tab-item value="specialties" class="tab-item">
        <specialties  :specialties="memberInfo.specialties"/>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import Biography from "@/components/team/details/Biography";
import PersonalInfo from "@/components/team/details/PersonalInfo";
import specialties from "@/components/team/details/specialties";
export default {
  props: {
    name: {
      type: String,
    },
    job: {
      type: String,
    },
  },
    metaInfo () {
      return {
        title: this.name,
        meta: [
          { name: this.job, content: this.job }
        ]
      }
    },
  components: {
    Biography,
    PersonalInfo,
    specialties,
  },
  data() {
    return {
      items: ["Biography", "Personal Info", "specialties"],
      tab: null,
      memberInfo: {},
    };
  },
  created() {
    this.memberInfo = this.$store.state.TeamMembers.find((item) => {
      return item.name == this.$route.params.name;
    });
  },
};
</script>

<style lang="scss" scoped>
.tab-item{
  margin: 10px 40px;
}
</style>

