<template>
  <v-app >
    <v-overlay :opacity="1" :value="overlay">
      <Splash />
    </v-overlay>

    <Nav v-if="overlay == false" />
    <v-main v-if="overlay == false">
      <router-view v-slot="{ Component }">
        <transition name="fade">
          <component :is="Component" />
        </transition>
      </router-view>
    </v-main>
    <Footer v-if="overlay == false" />
    <ScrollUpBtn />
  </v-app>
</template>
<style lang="scss" >
html,body{
  scroll-behavior: smooth !important;
}

@media only screen and (max-width: 1150px) {
  p {
    font-size: 16px  !important;
  }
}
@media only screen and (max-width: 600px) {
  p {
    font-size: 14px !important;
  }
}
p {
  font-size: 18px;
}

.sec-d-hd {
  padding-top: 110px;
  letter-spacing: 3px;
  font-family: "Open Sans";
  font-size: clamp(20px, 3vw, 40px);
}
* {
  font-family: $primaryFontFamily;
  scroll-behavior: smooth;
}
html,
body {
  height: 100%;
}
.greyBG {
  background-color: $pgrey !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

/* 
.container_d {
  background-image: url("https://images.unsplash.com/photo-1451187580459-43490279c0fa?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=872&q=80");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  max-width: 100vw;
  background-color: black;
  height: 5000px;
} */
</style>
<script>
import Nav from "@/components/global/Nav";
import Footer from "@/components/global/Footer";
import Splash from "@/components/global/Splash";
import ScrollUpBtn from "@/components/global/ScrollUpBtn.vue";

//import {consulting_axios} from "@/plugins/axios"
export default {
  name: "App",

  data: () => ({
    overlay: true,
   }),
  // async created() {
  //   // await consulting_axios.LoadSiteContent().then(res=>{
  //   //  if(res.data.data) this.UpdateSiteDataAction({ value: res.data.data });;
  //   // })
  //   // console.log(JSON.parse(this.$store.state.SiteData.find((member)=>member.Name=='home')?.Content).header);

  // },
  mounted() {
    // hide the overlay when everything has loaded
    // you could choose some other event, e.g. if you're loading
    // data asynchronously, you could wait until that process returns
    setTimeout(() => {
      this.overlay = false;
    }, 1);
    //handle Added non-passive event listener to a scroll-blocking <some> event violation caused by google maps
    (function () {
      if (typeof EventTarget !== "undefined") {
        let supportsPassive = false;
        try {
          // Test via a getter in the options object to see if the passive property is accessed
          const opts = Object.defineProperty({}, "passive", {
            get: () => {
              supportsPassive = true;
            },
          });
          window.addEventListener("testPassive", null, opts);
          window.removeEventListener("testPassive", null, opts);
        } catch (e) {}
        const func = EventTarget.prototype.addEventListener;
        EventTarget.prototype.addEventListener = function (type, fn) {
          this.func = func;
          this.func(type, fn, supportsPassive ? { passive: false } : false);
        };
      }
    })();
  },
  components: {
    Nav,
    Splash,
    ScrollUpBtn,
    Footer
  },
}
</script>
