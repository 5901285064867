<template>
  <v-container fluid class="pa-0 ma-0" id="contact">
    <PageHeader> CONTACT US </PageHeader>

    <UnderLinedHeader
      class="mt-4 white"
      :class="!$vuetify.breakpoint.smAndDown ? 'px-16' : 'mx-2'"
    >
      <p
        class="text-center mt-8"
        :class="!$vuetify.breakpoint.smAndDown ? 'px-16' : ''"
      >
        Let Us Help You Find Solutions for Your Business.
      </p>
    </UnderLinedHeader>
    <ContactMap ></ContactMap>

   
  </v-container>
</template>

<script>
import PageHeader from "@/components/global/compnentsMixins/PageHeader.vue";
import UnderLinedHeader from "@/components/global/compnentsMixins/UnderLinedHeader.vue";
import ContactMap from "@/components/contact/ContactMap.vue";
export default {
 metaInfo: {
    title: "Contact Us",
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { property:"og:locale", content:"en_US"},
      { property:"og:type", content: "Website"},
      { property:"og:title", content: "Contact Us"},
      { property:"og:url", content:"https://3545consulting.com/Contact/"},
      { property:"og:site_name", content:"3545 Consulting"},
      { property:"article:publisher", content:"https://www.facebook.com/pages/category/Business-Consultant/3545-Consulting-Global-211671325562596/"},
      { name:"twitter:card", content:"summary_large_image"},
      { name:"twitter:label1", content:"Est. reading time"},
      { name:"twitter:data1", content:"1 minutes"},
      { name:"geo.placename", content:"Houston"},
      { name:"geo.position", content:"294351.9;952534.4"},
    ]
  },
 
  components: { PageHeader, UnderLinedHeader, ContactMap },
};
</script>

<style lang="scss" scoped>
</style>