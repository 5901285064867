<template>
  <nav>
    <!-- last blog section   -->
    <!-- <section>
      <v-card color="#a81e2d" class="latest-blog">
        <v-card-text
          class="text-center white--text"
          style="font-family: Poppins; font-size: 16px"
          >Our Latest Blog: "End of Year 1099s: Knowing What to Send and When to
          Send It"
          <v-btn small class="black white--text ml-2">READ NOW</v-btn>
        </v-card-text>
      </v-card>
    </section> -->
    <!-- end  last blog section   -->

    <!-- contact  section   -->
    <v-dialog v-model="searchDialog" max-width="600px">
      <v-card>
        <div class="gcse-search"></div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="searchDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" timeout="600">
      copied!

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-card
      flat
      class="contacts-container mt-2 pl-7"
      v-if="!$vuetify.breakpoint.smAndDown"
    >
      <v-card-text>
        <v-row justify="center" align="center">
          <v-col
            class="white--text pl-16"
            cols="12"
            xl="6"
            lg="6"
            sm="6"
            s="12"
            md="6"
          >
            <span
              class="contacts"
              @click="
                openLink(
                  'https://www.google.com/maps/place/1980+Post+Oak+Blvd+Suite+100,+Houston,+TX+77056/@29.7461846,-95.4645061,17z/data=!3m1!4b1!4m6!3m5!1s0x8640c1e93fe04049:0x6e4b6eea523d1a3c!8m2!3d29.74618!4d-95.4619258!16s%2Fg%2F11jzsc2_k6?entry=ttu'
                )
              "
            >
              <v-icon small color="#a81e2d" class="mr-2">
                fas fa-map-marker-alt</v-icon
              >
              Houston, TX
            </span>
            <span
              class="pl-10 contacts"
              @click="openLink('mailto:info@3545consulting.com')"
            >
              <v-icon small color="#a81e2d" class="mr-2"> mdi-email</v-icon>
              info@3545consulting.com
            </span>
            <span
              class="pl-10 contacts"
              @click="
                () => {
                  copyToClipboard('713-789-3323');
                  this.snackbar = true;
                }
              "
            >
              <v-icon small color="#a81e2d" class="mr-2"> mdi-phone</v-icon>
              713-789-3323
            </span>
          </v-col>

          <v-col
            cols="12"
            xl="6"
            lg="6"
            sm="6"
            s="12"
            md="6"
            justify="end"
            align="end"
            class="pr-16"
          >
            <span class="socials">
              Follow us on:

              <v-btn
                href="https://www.facebook.com/3545ConsultingGlobal"
                target="_blank"
                icon
              >
                <v-icon small class="social" color="#a81e2d">
                  fab fa-facebook-f</v-icon
                >
              </v-btn>
              <v-btn
                class="ml-1"
                href="https://www.linkedin.com/company/3545consulting/"
                target="_blank"
                icon
              >
                <v-icon small color="#a81e2d" class="social">
                  fab fab fa-linkedin-in</v-icon
                >
              </v-btn>

              <v-btn
                href="https://twitter.com/3545_Consulting"
                target="_blank"
                icon
                class="ml-1"
              >
                <v-icon small color="#a81e2d" class="social">
                  fab fa-twitter</v-icon
                >
              </v-btn>
            </span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!--end contact section -->

    <v-app-bar
      static
      id="nav"
      flat
      class="transparent mb-2 mt-1 pa-0"
      :class="!$vuetify.breakpoint.smAndDown ? 'pl-16 mr-0 pr-2' : 'pl-2'"
      :height="$vuetify.breakpoint.smAndDown ? 100 : 70"
    >
      <v-app-bar-title>
        <v-img
          class="p-logo"
          @click="$router.push({ name: 'Landing' }).catch((err) => {})"
          alt="3545 consulting"
          src="@/assets/imgs/logo-header.png"
        ></v-img>
      </v-app-bar-title>
      <v-spacer></v-spacer>

      <v-list
        flat
        class="horezental d-flex transparent pa-0"
        align="start"
        v-if="!$vuetify.breakpoint.smAndDown"
      >
        <v-list-item
          class="mt-2 pa-0 transparent pt-6"
          v-for="(item, index) in this.navItems"
          :key="index"
          :to="item.link"
        >
          <!--sub menu first level -->
          <v-menu
            class="pa-0 transparent firstlevel"
            offset-y
            :disabled="!item.subItems"
            close-delay="300"
            offset-overflow
            open-on-hover
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="nav-item px-0 mx-3" text v-bind="attrs" v-on="on">
                {{ item.name }}
              </v-btn>
            </template>
            <v-card
              width="500"
              v-if="HasGrandsons(item)"
              max-height="400"
              class="pr-2"
            >
              <v-row no-gutters>
                <template v-for="(item, index) in item.subItems">
                  <v-col :key="index">
                    <v-row justify="center" align="center">
                      <v-col>
                        <v-card flat>
                          <v-card-subtitle
                            :to="item.link"
                            style="font-size: 18px !important; color: black"
                            class="mt-8 text-center"
                          >
                            <a style="color: black" :href="item.link">{{
                              item.title
                            }}</a>
                          </v-card-subtitle>
                          <v-card-actions>
                            <v-list>
                              <template v-for="(subitem, i) in item.subItems">
                                <v-list-item
                                  :key="i"
                                  class="ml-6 sub-item-container"
                                  :to="
                                    subitem.link &&
                                    subitem.link.substring(0, 1) == '/'
                                      ? subitem.link
                                      : null
                                  "
                                  :href="
                                    subitem.link &&
                                    subitem.link.substring(0, 1) != '/'
                                      ? subitem.link
                                      : null
                                  "
                                  style="color: transparent !important"
                                >
                                  <v-list-item-subtitle
                                    class="sub-sub-text"
                                    style="
                                      font-size: 12px !important;
                                      color: black;
                                    "
                                  >
                                    {{ subitem.title }}
                                  </v-list-item-subtitle>
                                </v-list-item>
                              </template>
                            </v-list>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col
                    cols=".01"
                    class="text-center py-16 mx-n16"
                    style="width: 2px !important"
                    :key="index + 'navD'"
                    v-if="index < item.subItems.length - 1"
                  >
                    <v-divider vertical />
                  </v-col>
                </template>
              </v-row>
            </v-card>
            <v-list width="280px" v-else>
              <template v-for="(x, i) in item.subItems">
                <v-list-item
                  :key="i"
                  class="ml-6 sub-item-container"
                  :to="x.link && x.link.substring(0, 1) == '/' ? x.link : null"
                  :href="
                    x.link && x.link.substring(0, 1) != '/' ? x.link : null
                  "
                  :target="
                    x.link && x.link.substring(0, 1) != '/' && !x.notblank
                      ? '_blank'
                      : null
                  "
                  style="color: transparent !important"
                >
                  <v-list-item-subtitle class="sub-item">
                    <span>{{ x.title }}</span>
                  </v-list-item-subtitle>
                </v-list-item>
                <v-divider
                  class="ml-10 mr-7"
                  v-if="i < item.subItems.length - 1"
                  :key="`d` + i"
                ></v-divider>
              </template>
            </v-list>
          </v-menu>

          <!-- <v-list-item-title
            ><a :href="item.ref" class="nav-item">{{
              item.name
            }}</a></v-list-item-title> -->
        </v-list-item>
        <v-list-item class="mt-2 ml-2" @click="openSearchDialog">
          <v-list-item-title>
            <v-icon size="20" color="black" class="mt-1 ml-2"
              >mdi-magnify</v-icon
            >
          </v-list-item-title>
        </v-list-item>
      </v-list>

      <!-- 
        <v-list dense class="vertical transparent mx-auto " v-if="$vuetify.breakpoint.smAndDown && drawer">
        <v-list-item
          class=""
          v-for="(item, index) in this.navItems"
          :key="index"
        >
          <v-list-item-title
            ><a href="#solution" class="nav-item">
              <h5>{{item.name}}</h5>
            </a></v-list-item-title
          >
        </v-list-item>
      </v-list> -->
      <!-- v-if="$vuetify.breakpoint.smAndDown" -->
      <template v-if="$vuetify.breakpoint.smAndDown">
        <v-spacer></v-spacer>
        <v-icon
          @click="openSearchDialog"
          color="white"
          class="pa-2"
          style="background-color:#a81e2d; !important"
          >mdi-magnify</v-icon
        >

        <v-icon
          @click="dialog = true"
          color="white"
          class="pa-2 ml-3"
          style="background-color:#a81e2d; !important"
        >
          mdi-menu</v-icon
        >
      </template>
    </v-app-bar>

    <!--Navigation bar start -->

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card color="black">
        <v-toolbar flat color="black darken-2">
          <v-spacer></v-spacer>
          <v-btn icon @click.native="dialog = false">
            <v-icon color="#a81e2d">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-expansion-panels accordion class="pa-4">
          <v-expansion-panel
            v-for="(item, i) in navItems"
            :key="i"
            @click="!item.subItems ? openSingleLink(item.link) : null"
          >
            <v-expansion-panel-header
              class="white"
              style="color: black !important"
              >{{ item.name }}</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <template v-for="(subItem, i) in item.subItems">
                <v-list-item
                  v-if="!subItem.subItems"
                  :key="i"
                  class="ml-6"
                   :to="
                            subItem.link && subItem.link.substring(0, 1) == '/'
                              ? subItem.link
                              : null
                          "
                          :href="
                            subItem.link && subItem.link.substring(0, 1) != '/'
                              ? subItem.link
                              : null
                          "
                          :target="
                            subItem.link &&
                            subItem.link.substring(0, 1) != '/' &&
                            !subItem.notblank
                              ? '_blank'
                              : null
                          "
                  style="color: transparent !important"
                  @click="dialog = false"
                >
                  <v-list-item-subtitle class="sub-item">{{
                    subItem.title
                  }}</v-list-item-subtitle>
                </v-list-item>
                <v-expansion-panels accordion class="pa-1" :key="i" v-else>
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      class="white"
                      style="color: black !important"
                      >{{ subItem.title }}</v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                      <template v-for="(subSubItem, subSubindex) in subItem.subItems">
                        <v-list-item
                          :key="subSubindex"
                          class="ml-6"
                          :to="subSubItem.link"
                          style="color: transparent !important"
                          @click="dialog = false"
                        >
                          <v-list-item-subtitle class="sub-item">{{
                            subSubItem.title
                          }}</v-list-item-subtitle>
                        </v-list-item>
                        <v-divider
                          class="ml-10 mr-7"
                          v-if="i < subItem.subItems.length - 1"
                          :key="`d` + subSubindex"
                        ></v-divider>
                      </template>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-divider
                  class="ml-10 mr-7"
                  v-if="i < item.subItems.length - 1 && !subItem.subItems"
                  :key="`d` + i"
                ></v-divider>
              </template>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-dialog>
  </nav>
</template>

<style lang="scss" scoped>
.v-card {
  overflow: hidden;
}

.sub-sub-text:hover {
  color: $pred !important;
}

.sub-sub-item {
  &:hover .v-icon {
    color: $pred;
  }
}

.v-menu__content {
  margin-top: 9px;

  &:hover {
    display: block !important;
  }
}

.contacts-container {
  border-bottom: 0.3px solid rgb(230, 230, 233) !important;
}

.p-logo {
  cursor: pointer;
}

.sub-item-container,
.sub-item,
.sub-item span {
  transition-delay: 0.1s !important;
  transition: 0.4s ease-in-out !important;
}

.sub-item-container:hover .sub-item span {
  color: $pred !important;
  padding-left: 20px !important;
}

.sub-item::before {
  font-family: "Font Awesome 5 Free";
  content: "\f105";
  font-weight: 900;
  top: 35%;
  color: $pred !important;
  opacity: 0;
  transition-delay: 0.3s !important;

  transition: 0.4s ease-in-out !important;
}

.sub-item-container:hover .sub-item::before {
  color: $pred !important;
  opacity: 1;

  color: $pred !important;
  position: absolute;
}

.sub-item {
  transition: all 0.5s ease-out;

  span {
    transition: all 0.5s ease-out;
    transition-delay: 0.3s ease-out;
  }
}

.contacts {
  font-size: 12px;

  color: black !important;
  cursor: pointer;

  &:hover {
    color: $pred !important;

    i {
      color: black !important;
    }
  }
}

.social:hover {
  color: black !important;
  cursor: pointer;
}

a {
  text-decoration: none;
}

.nav-item {
  @include animateBorder($direction: "left");
  text-transform: uppercase;
  font-family: $primaryFontFamily;
  /*"Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;*/
  transition: all 1s;

  &:hover {
    background-color: transparent !important;
  }

  &::before {
    display: none;
  }
}

/* header:not(v-app-bar--is-scrolled) {
  background-color: black !important;
}
.v-app-bar--is-scrolled {
  background-color: aqua !important;
}*/
</style> 
<script>
import DataMixin from "@/components/global/mixins/DataMixin.js";
export default {
  name: "Nav",

  data() {
    return {
      searchDialog: false,
      snackbar: false,
      dialog: false,
      tab: null,
      navItems: [
        {
          name: "Solutions",
          link: "/Products",
          /*subItems: [
            // {
            //   title: "Services",
            //   subItems: [
            //     {
            //       title: "Business Solutions and Strategies",
            //       link: "/#",
            //     },
            //     {
            //       title: "Network and Technology",
            //       link: "/#",
            //     },
            //     {
            //       title: "Training",
            //       link: "/#",
            //     },
            //   ],
            // },
            {
              title: "Products",
              link: "/Products",
              subItems: [
                {
                  title: "Practice Management",
                  link: "/Products/Practice Management",
                },
                {
                  title: "Time Billing/Accounting",
                  link: "/Products/Time Billing___Accounting",
                },
                {
                  title: "Document Assembly",
                  link: "/Products/Document Assembly",
                },
                {
                  title: "Document Management",
                  link: "/Products/Document Management",
                },
                {
                  title: "Automation",
                  link: "/Products/Automation",
                },
                {
                  title: "CRM",
                  link: "/Products/CRM",
                },
              ],
            },
            {
              title: "Support",
              subItems: [
                {
                  title: "YES Plan",
                  link: "/#",
                },
                {
                  title: "Managed Services Plan (MSP)",
                  link: "/#",
                },
              ],
            },
          ],*/
          subItems: [
            {
                  title: "Practice Management",
                  link: "/Products/Practice Management",
                },
                {
                  title: "Time Billing/Accounting",
                  link: "/Products/Time Billing___Accounting",
                },
                {
                  title: "Document Assembly",
                  link: "/Products/Document Assembly",
                },
                {
                  title: "Document Management",
                  link: "/Products/Document Management",
                },
                {
                  title: "Automation",
                  link: "/Products/Automation",
                },
                {
                  title: "CRM",
                  link: "/Products/CRM",
                },
          ],
        },
        {
          name: "company",
          subItems: [
            { title: "About 3545", link: "/About" },
            { title: "Our Team", link: "/team" },
            // { title: "Our Partners" },
          ],
        },

        // {
        //   name: "services",
        //   icon: "fas fa-shopping-bag",
        //   ref: "#products",
        //   subItems: [
        //     { title: "Consulting", link: "/Consulting" },
        //     { title: "IT & Network Services", link: "/IT_NetworkServices" },
        //     { title: "Managed Services", link: "/managed-services" },
        //     {
        //       title: "Business Pulse Analytics",
        //       link: "/business-pulse-analytics",
        //     },
        //     { title: "Custom Applications", link: "/custom-applications" },
        //   ],
        // },

        {
          name: "resources",
          subItems: [
            {
              title: "Case Studies",
              link: "/CaseStudies",
            },
            {
              title: "Blog",
              link: "http://blog.3545consulting.com/",
              notblank: true,
            },
          ],
        },

        {
          name: "contact us",
          link: "/Contact",
        },
        {
          name: "clients",
          subItems: [
            {
              title: "Remote Access",
              link: "https://3545sc.screenconnect.com/",
            },
            {
              title: "Send A Payment",
              link: "/SendAPaymemnt",
            },
            {
              title: "LMS Login",
              link: "https://3545consulting.cliptraining.com/",
            },
            {
              title: "Reset Your Password",
              link: "https://app.getquickpass.com/",
            },
          ],
        },
      ],
    };
  },
  mixins: [DataMixin],
  methods: {
    openSingleLink(link) {
      if (this.$router.currentRoute.path != link) {
        this.$router.push({ path: link });
        this.dialog = false;
      }
    },
    openSearchDialog() {
      console.log("called");
      this.searchDialog = true;
      const googleSearchScript = document.createElement("script");

      googleSearchScript.setAttribute(
        "src",
        "https://cse.google.com/cse.js?cx=c12b2a707bbfbcf84"
      );
      document.head.appendChild(googleSearchScript);
    },
    // open(e){
    //   console.log(!document.querySelector('.v-menu__content') || !document.querySelector('v-menu__content')?.classList.contains('menuable__content__active') )
    //     if(!document.querySelector('v-menu__content')?.classList.contains('menuable__content__active') ){
    //         e.target.querySelector('.nav-item').click();
    //     }
    // },
    HasGrandsons(item) {
      if (item.subItems == null) return false;
      //console.log(item.subItems.some((obj) => obj.hasOwnProperty("subItems")));
      return item.subItems.some((obj) => obj.hasOwnProperty("subItems"));
    },
    goTo(index) {
      this.goToId(this.navItems[index].link);
    },
  },
};
</script>