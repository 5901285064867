<template>
  <OneDirectionSlider :items="this.items">
    <template v-slot:header> Proudly Supported Products </template>
    <template v-slot:content>
      <SplideSlide v-for="(item, index) in items" :key="index">
        <img
          contain
          :src="
            require('@/assets/imgs/ProudlySupportedProducts/' + item.imgName)
          "
          :alt="item.name"
        />
      </SplideSlide>
    </template>
  </OneDirectionSlider>
</template>
<script>
import OneDirectionSlider from "@/components/global/OneDirectionSlider.vue";
import { SplideSlide } from "@splidejs/vue-splide";
export default {
  data() {
    return {
      items: [
        {
          name: "timeslips",
          imgName: "timeslips.png",
        },
        {
          name: "PCLawNEW",
          imgName: "PCLawNEW.jpg",
        },
        {
          name: "timeslips",
          imgName: "timeslips.png",
        },
        {
          name: "timeslips",
          imgName: "timeslips.png",
        },
        {
          name: "hotdocs",
          imgName: "hotdocs.jpg",
        },
        {
          name: "CaretLegal",
          imgName: "CaretLegal.jpg",
        },
      ],
    };
  },
  components: {
    OneDirectionSlider,
    SplideSlide,
  },
};
</script>