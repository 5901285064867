<template>
  <v-row justify="start" class="filters-row">
    <v-col cols="12">
      <v-text-field
        style="max-width: 290px"
        color="#a81e2d"
        dark
        prepend-inner-icon="mdi-magnify"
        clearable
        height="50"
        label="Search"
        solo
        dense
        @input="updateSearchQuery"
      ></v-text-field>
    </v-col>
  </v-row>
</template>
<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions(["UpdateSearchQueryAction"]),
    updateSearchQuery(input) {
      this.UpdateSearchQueryAction({ value: input });
      console.log(this.$store.state.SearchQuery);
    },
  },
};
</script>
<style scoped lang="scss">


</style>

