<template>
  <v-main>
    <v-content class="content">
      <v-container fluid>
        <v-row style="background-color: #3a3a3a" align="center" class="pa-16">
          <v-col align="center">
            <h1 class="px-10 white--text pt-0">
              Is It Time To Kick Your Server To The Curb?
            </h1>
          </v-col>
        </v-row>
        <v-row justify="space-around">
          <v-col cols="8" class="col-xs-10 col-sm-10 col-md-10 col-lg-8">
            <v-img
              src="img/blogs/shutterstock_144563243-cropped.jpg"
              aspect-ratio="1.7"
              alt="Kick_Your_Server"
            ></v-img>
          </v-col>
        </v-row>
        <v-row justify="space-around">
          <v-col cols="8" class="col-xs-10 col-sm-10 col-md-10 col-lg-8">
            <article>
              <div class="entry-content">
                <div class="entry-content-inner">
                  <p>
                    When it comes to office partnerships, few may be as intimate
                    as the relationship with your server. &nbsp;That gentle hum
                    can be a cool tonic that soothes a frantic search for client
                    files, the expedited crunching of data, or the inputting of
                    employee hours. &nbsp;The server doesn’t need compliments,
                    nor smoke-breaks.&nbsp; With a few finger-tip clicks, it’s
                    there to serve you upon request. &nbsp;And unlike some
                    co-workers, the server is always on time and doing the
                    fastest job possible… until it isn’t. &nbsp;As with all
                    electronics, servers don’t live forever.&nbsp; In fact,
                    computer gurus admit new technologies have shortened server
                    lifecycles.&nbsp; So how do you know when it’s time to say
                    Adios Amigo? &nbsp;And what’s the best technology to replace
                    it?
                  </p>

                  <p><strong>Signs of End Times</strong></p>

                  <p>
                    Experts now recommend office managers and decision makers
                    should expect to replace servers every two to three years.
                    They can last as long as five, but forcing these new-fangled
                    sprinters to run a marathon is risky and could compromise
                    office operations.&nbsp; The crash will come. It’s just a
                    matter of when. (And you know with Murphy’s Law, it will hit
                    right before a major deadline.) &nbsp;&nbsp;
                  </p>

                  <p>
                    The fact is, over time, servers will get slower. They won’t
                    keep pace with the workload, and you’ll notice a diminishing
                    performance.&nbsp; Ignition is literally a “grind,” searches
                    take longer, and programs may seize.&nbsp; Eventually,
                    compatibility issues will arise.&nbsp; As each new
                    generation of hardware is introduced, specific programs and
                    applications stop downloading updates and patches on vintage
                    machines. This, in turn, can leave hundreds of files and
                    critical information at risk to security threats, bugs, and
                    loss. In addition, once servers have reached “end of life”
                    status, they may not meet compliance and liability standards
                    needed for many clients and banking functions.
                  </p>

                  <p><strong>The Cost of Clinging</strong></p>

                  <p>
                    Those focused on the bottom line should realize the cost of
                    maintaining an older server increases as they age.&nbsp; Old
                    servers use more energy and generate more heat. This forces
                    offices to keep temperatures lower within server storage
                    spaces.&nbsp; Server failure rate increases as the heat
                    compromises components.&nbsp; The short-term fix to replace
                    components, may sound enticing, but is cost prohibitive.
                    Replacement parts are typically harder to find for older
                    units, and thus more expensive. And the biggie to note: once
                    a drive fails, others usually follow in a ripple effect,
                    like a chain of falling dominos. &nbsp;
                  </p>

                  <p>
                    Once servers fall out of warranty, the mentioned costs and
                    down-time magnifies exponentially. Those within warranty
                    receive rescue in a matter of hours. Out of warranty fixes
                    may take days, as manufacturers and vendors are no longer
                    responsible for providing parts and repairs.&nbsp; This in
                    turn, can force a firm to shut down while waiting for access
                    to needed files, which can cost a company more than
                    dollars.
                  </p>

                  <p><strong>Ineffectiveness of Doubling Down</strong></p>

                  <p>
                    To prevent such scenarios of loss and downtime, some firms
                    utilize servers to back up servers.&nbsp; There’s the file
                    server, the email exchange server, and domain controller.
                    The list of redundancy goes on and on… along with the space
                    and costs to maintain duplicate information in the face of
                    possible failure.&nbsp; During recent weather crisis, some
                    firms learned the hard way that duplicate servers are still
                    no match to Mother Nature and flooding.&nbsp; (For some, the
                    size of the servers even prevented moving them to safer
                    surroundings.)&nbsp;<br />
                  </p>

                  <p><strong>The Solution</strong></p>

                  <p>
                    The answer is as simple as looking up. The Cloud.&nbsp; All
                    of the issues that come with physically housing a server
                    dissipate. Fix-it and maintenance costs disappear. Upgrades
                    are immediate, keeping pace with changes in program
                    compatibility, security, and access.&nbsp; A firm’s files
                    are safe and accessible all day, every day, from anywhere.
                    And the speed?&nbsp; Rapid-fire, especially when compared to
                    the slow spinning of an aging server.
                  </p>

                  <p>
                    Not all relationships are meant to last forever.&nbsp; It’s
                    time to order a cake and balloons, and host an office
                    good-bye party to that aging server.&nbsp; You can send it
                    off into retirement, knowing its replacement is waiting
                    anxiously in the cloud, ready to step in and work that much
                    more efficiently.
                  </p>
                </div>
                <div>
                  <div class="contact">Contact Us for a Free Consultation</div>
                  <div class="my-2">
                    <v-btn
                      color="#a81e2d"
                      dark
                      large
                      href="https://share.hsforms.com/1vHOvvLGcQgaCrRebQNvi-Q3gv9a"
                      target="_blank"
                    >
                      Contact Us Today
                    </v-btn>
                  </div>
                </div>
              </div>
            </article>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </v-main>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
article .entry-content {
  padding-top: 20px;
}
.entry-content-inner {
  width: 100%;
  text-align: justify;
  line-height: 23px;
}
p {
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
}
p strong {
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
}
.contact {
  font-size: 22px !important;
  font-weight: 700;
}
.content {
  background: #f8f9f9;
}
</style>