export default {
    UpdateTeamMembersAction: ({ commit }, payload) => {
        //console.log(payload)
        commit({ type: 'UpdateTeamMembers', ...payload })
    },
    UpdateSearchQueryAction: ({ commit }, payload) => {
        //console.log(payload)
        commit({ type: 'UpdateSearchQuery', ...payload })
    },
    UpdateTeamfiltersAction: ({ commit }, payload) => {
        //console.log(payload)
        commit({ type: 'UpdateTeamfilters', ...payload })
    },
    UpdateSpecfiltersAction: ({ commit }, payload) => {
        //console.log(payload)
        commit({ type: 'UpdateSpecfilters', ...payload })
    },
    UpdateSiteDataAction: ({ commit }, payload) => {
        //console.log(payload)
        commit({ type: 'UpdateSiteData', ...payload })
    },


}

