<template>
  <hoveredList :items="specialties" />
</template>

<style lang="scss" scoped>
//@import '@/styles/main.scss';
.itm {
  height: 20px !important;
  .itm-text {
    &:hover {
      color: $pred;
    }
  }
}
</style>

<script>
import HoveredList from "@/components/global/compnentsMixins/HoveredList.vue"
export default {
  components: {HoveredList},
  props: {
    specialties: {
      type: Array,
    },
  },
};
</script>