<template>
  <v-row class="mt-8 mb-5">
    <v-col cols="12" xs="12" lg="4" xl="4" md="4">
      <v-card flat>
        <ImageCard
           :minHeight_c="-1"
          :maxHeight_c="-1"
          :imageName="member.imgPath"
          :alt="member.name"
          parentFolder="team"
        />
        <v-card-title>{{ member.name }}</v-card-title>
        <v-card-text>{{ member.job }}</v-card-text>
        <v-card-subtitle>{{ member.categories[0] }}</v-card-subtitle>
      </v-card>
    </v-col>
    <v-col cols="12 " xs="12" lg="8" xl="8" md="8">
      <div
        class="about"
        :class="!$vuetify.breakpoint.smAndDown ? 'px-16' : ''"
        v-html="member.about +'</br></br>'"
      ></div>

      <v-card
        flat
        class="about mt-3 ml-3"
        :class="!$vuetify.breakpoint.smAndDown ? 'px-16' : ''"
      >
        <v-row class="justify-start" v-if="member.say">
          <v-icon color="#a81e2d">fas fa-quote-left</v-icon></v-row
        >
        <v-row>
          <p
            class="say my-6 font-italic font-weight-medium"
            :style="[
              !$vuetify.breakpoint.smAndDown
                ? { 'font-size': '24px' }
                : { 'font-size': '18px' },
            ]"
          >
            {{ member.say }}
          </p>
        </v-row>

        <v-row class="justify-end pr-4" v-if="member.say">
          <v-icon color="#a81e2d">fas fa-quote-right</v-icon></v-row
        >
      </v-card>
    </v-col>
  </v-row>
</template>

<style lang="scss" scoped>
.about {
  font-family: $primaryFontFamily;
  margin-right: 30px;
  font-size: 14px;
  /*font-weight: lighter ;*/
  color: $textcolor !important ;
}
.say {
  font-family: $primaryFontFamily;
 
}
</style>

<script>
import ImageCard from "@/components/global/ImageCard";

export default {
  components: { ImageCard },
  props: {
    member: {
      type: Object,
    },
  },
};
</script>