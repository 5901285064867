import Vue from 'vue'
import VueRouter from 'vue-router'
import Landing from '../views/Landing.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: Landing
  },
  {
    path: '/team',
    name: 'Team',
    component:()=> import("../views/Team.vue")
  },
  {
    path: '/team/:name',
    name: 'Details',
    component:()=> import("../views/Details.vue")
  },
  {
    path: '/about',
    name: 'About',
    component:()=> import("../views/About.vue")
  }
  ,
  {
    path: '/about-us-2-4',
    name: 'About2',
    component:()=> import("../views/About.vue")
  }
  ,
  {
    path: '/Contact',
    name: 'Contact',
    component:()=> import("../views/Contact.vue")
  }
  ,
  {
    path: '/contact-us-3/',
    name: 'Contact2',
    component:()=> import("../views/Contact.vue")
  }
  ,
  {
    path: '/SendAPaymemnt',
    name: 'SendAPaymemnt',
    component:()=> import("../views/clients/SendAPaymemnt.vue")
  }
  ,
  {
    path: '/CaseStudies',
    name: 'CaseStudies',
    component:()=> import("../views/Resources/CaseStudies.vue")
  },
  {
    path: '/HelpingSecureYourSensitiveData',
    name: 'HelpingSecureYourSensitiveData',
    component:()=> import("../views/Resources/HelpingSecureYourSensitiveData.vue")
  },
  {
    path: '/FindingtheMVPofMSPs',
    name: 'FindingtheMVPofMSPs',
    component:()=> import("../views/Resources/FindingtheMVPofMSPs.vue")
  },
  {
    path: '/IsItTimetoKickYourServertotheCurb',
    name: 'IsItTimetoKickYourServertotheCurb',
    component:()=> import("../views/Resources/IsItTimetoKickYourServertotheCurb.vue")
  },
  {
    path: '/Products/:id?',
    name: 'Products',
    component:()=> import("../views/solusions/Products.vue")
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
