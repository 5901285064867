<template>
  <v-row style="background-color: #f8f9f9" align="center"  class="pb-0 pt-3 " no-gutters>
    <v-col :align="align">
      <h1 class="Black  "><slot></slot></h1>
      <hr class="text-left" />
    </v-col>
  </v-row>
</template>



<style scoped lang="scss">
 @media only screen and (max-width: 1150px) {
h1,h2,p {
  font-size: 24px !important;
}
}
@media only screen and (max-width: 600px) {
h1,h2,p {
  font-size: 18px !important;
}
}
h1,h2,p {
  font-family: $primaryFontFamily;
  color: #222222;
  font-size: 35px;
}
hr {
  border-top: 2px solid $pred;
  width: 60px;
  margin-top: 20px;
}
</style>

<script>
export default {
  props:{
    align:{
      type: String,
      default:"center"
    }
  }
}
</script>