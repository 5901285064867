<template>
  <div class="flip-card">
    
    <div class="flip-card-inner">
      <div class="flip-card-front">
        <slot name="front"></slot>
      </div>

      <div class="flip-card-back">
        <div class="card_text_style">
          <slot name="back"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.flip-card {
  background-color: transparent;
  width: 355px;
  height: 255px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  /*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);*/
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: rgb(255, 255, 255);
  color: black;
}

.flip-card-back {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  transform: rotateY(180deg);
}
.card_text_style {
  margin: auto;
  padding-bottom: 20px;
}
</style>