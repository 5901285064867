<template>
  <div>
    <v-dialog
      absolute
      transition="dialog-top-transition"
      max-width="600"
      v-model="dialog"
    >
      <template>
        <v-card>
          <v-toolbar color="primary" dark>Contact Us</v-toolbar>
          <v-card-text class="pt-6">
            <div ref="hubspotForm" id="hubspotForm" v-once></div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="dialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>

    <v-row class="mt-4" justify="center" align="center" >
      <v-col
        justify="center"
        align="center"
        class="mt-4 map-col"
        cols="12"
        height="800"
       
      >
        <iframe
          class="map-frame"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3464.087365296998!2d-95.46450608930766!3d29.746184632537474!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c1e93fe04049%3A0x6e4b6eea523d1a3c!2s1980%20Post%20Oak%20Blvd%20Suite%20100%2C%20Houston%2C%20TX%2077056!5e0!3m2!1sen!2sus!4v1688693717028!5m2!1sen!2sus"
          frameborder="0"
          allowfullscreen
        >
        </iframe>
        <v-row 
          class="px-16 contact-row"
          justify="center"
          align="center"
          no-gutters
          style="top: 40%;  z-index: 2 !important;"
          :style="!$vuetify.breakpoint.smAndDown ? {'right':'0','left':'0'}:{}"
        >
          <v-col
            justify="center"
            align="center"
            cols="12"
            s="12"
            xs="12"
            lg="3"
            xl="3"
            md="12"
            v-for="(item, index) in items"
            :key="index"
          >
            <v-card class="text-center" :class="!$vuetify.breakpoint.smAndDown?'pa-8':'pt-6 my-3'">
              <v-icon color="#a81e2d" v-text="item.icon"></v-icon>
              <v-card-title class="justify-center">
                {{ item.title }}
              </v-card-title>
              <v-card-text
              :style="!$vuetify.breakpoint.smAndDown ? {'min-width':'250px'}:{}"
                style="max-height: 50px !important; "
                v-html="item.subtitle"
              ></v-card-text>
              <!-- href="https://app.hubspot.com/forms/5825854" -->
              <v-card-text
                class="pt-8"
                v-on="
                  item.action == 'SEND A MESSAGE'
                    ? { click: openContactForm }
                    : item.action == 'DIRECTIONS'
                    ? { click: openDirections }
                    : {}
                "
              >
                <a
                  :href="
                    item.action == 'OPEN A TICKET'
                      ? 'Mailto: incident@3545consulting.com'
                      : null"
                  style="color: #a81e2d !important; text-decoration: none"
                  >{{ item.action }}
                </a></v-card-text
              >
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  methods: {
    openDirections() {
      this.openLink(
        "https://www.google.com/maps/place/1980+Post+Oak+Blvd+Suite+100,+Houston,+TX+77056/@29.74618,-95.4619258,17z/data=!3m1!4b1!4m6!3m5!1s0x8640c1e93fe04049:0x6e4b6eea523d1a3c!8m2!3d29.74618!4d-95.4619258!16s%2Fg%2F11jzsc2_k6?entry=ttu"
      );
    },
    openContactForm() {
      this.dialog = true;
      if (!this.$refs.hubspotForm || this.$refs.hubspotForm.innerHTML === "") {
        const script = document.createElement("script");
        script.src = "https://js.hsforms.net/forms/v2.js";
        document.body.appendChild(script);
        script.addEventListener("load", () => {
          if (window.hbspt) {
            window.hbspt.forms.create({
              portalId: "5825854",
              formId: "a265c4b5-4606-4254-8594-3c5820f08fcf",
              target: "#hubspotForm",
            });
          }
        });
      }
    },
  },
  data() {
    return {
      dialog: false,
      items: [
        {
          icon: "mdi-map-marker",
          title: "Our Address",
          subtitle: "3545 Consulting Global  <br/>1980 Post Oak Blvd, Ste 100 <br/>Houston, TX 77056",
          action: "DIRECTIONS",
        },
        {
        icon: "mdi-mail",
          title: "Remittance Address",
          subtitle: "<strong>Please send all payments to this address:</strong><br/> 3545 Consulting Global  <br/>PO Box 737581 <br/>Dallas, TX 75373-7581",
          action: "_" ,
        },
        {
          icon: "mdi-email",
          title: "Contact Info",
          subtitle: "Email: info@3545consulting.com <br/>Phone: 713-789-3323",
          action: "SEND A MESSAGE",
        },
        {
          icon: "mdi-account-multiple ",
          title: "Our Support",
          subtitle:
            "<strong>Main Support:</strong><br/>incident@3545consulting.com <br/>Phone: 713-789-3323",
          action: "OPEN A TICKET",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.map-frame {
  position: relative;
  width: 100%;

}

.map-col{
  padding-top: 0%;
}
.contact-row{
      margin-top:50px;
}
@media only screen and (min-width: 1264px) {
  .contact-row {
    position: absolute;
  }
  .map-frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60%;
  z-index: 1;
}

.map-col{
 position: relative;
  padding-top: 56.25%;
}
}
</style>
