<template>
  <v-card flat :loading="loading">
    <v-img
      :min-height="minHeight_c"
      :max-height="maxHeight_c"
      :src="require('@/assets/imgs/' + parentFolder + '/' + imageName)"
      :lazy-src="require('@/assets/imgs/' + parentFolder + '/' + imageName)"
      :alt="alt"
      @load="loading = false"
      @loadstart="loading = true"
    ></v-img>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
    };
  },
  props: {
    minHeight_c: {
      type: Number,
      default: 300,
    },
    maxHeight_c: {
      type: Number,
      default: 300,
    },
    /*minWidth_c: {
      type: Number,
      default: 100,
    },
    maxWidth_c: {
      type: Number,
      default: 100,
    },*/
    imageName: {
      type: String,
    },
    alt: {
      type: String,
    },
    parentFolder: {
      type: String,
      default: "",
    },
  },
};
</script>