<template>
  <v-container fluid class="pa-0 ma-0">
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12" justify="center" align="center">
        <v-img
          width="100%"
          :src="require('@/assets/imgs/' + imgNmae)"
          max-height="265"
          gradient="to left,  rgba(0, 0, 0, 0.2) ,rgba(0, 0, 0, .2)"
        >
          <v-row justify="center" align="center" style="height: 100%">
            <v-col cols="12" justify="center" align="center">
              <div class="header" :style="$vuetify.breakpoint.mdAndDown? { 'font-size': '54px'}:{ 'font-size': '36px'}">
                <span>Product Solutions</span>
              </div>
            </v-col>
          </v-row>
        </v-img>
      </v-col>
    </v-row>
    <v-row no-gutters
      justify="center"
      align="center"
      :class="$vuetify.breakpoint.mdAndDown ? 'px-2' : 'px-6 '"
      class="mt-8"
    >
      <p
        class="artice-text text-center"
        :class="$vuetify.breakpoint.mdAndDown ? '' : 'px-8'"
      >
        Finding the right software or applications to fit the needs of your firm
        can be a daunting task. You are investing in the future of your firm. We
        work with a large network of top technology vendors and have decades of
        experience to help you make the best decision to fit your requirements
        and budget. If you would like to schedule a demo or learn more about a
        product, we are here to assist you.
      </p>
    </v-row>
    <ProductsExpansionPanel />
  </v-container>
</template>

<script>
import ProductsExpansionPanel from '@/components/products/ProductsExpansionPanel.vue'
export default {
  metaInfo: {
    title: "Products",
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { property:"og:locale", content:"en_US"},
      { property:"og:type", content: "article"},
      { property:"og:title", content: "Products - 3545 Consulting"},
      { property:"og:url", content:"https://3545consulting.com/Products/"},
      { property:"og:site_name", content:"3545 Consulting"},
      { property:"article:publisher", content:"https://www.facebook.com/pages/category/Business-Consultant/3545-Consulting-Global-211671325562596/"},
      { name:"twitter:card", content:"summary_large_image"},
      { name:"twitter:label1", content:"Est. reading time"},
      { name:"twitter:data1", content:"2 minutes"},
      { name:"geo.placename", content:"Houston"},
      { name:"geo.position", content:"294351.9;952534.4"},
    ]
  },
  components: { ProductsExpansionPanel },

  data() {
    return {
      imgNmae: "products.jpg",
    };
  },
};
</script>

<style lang="scss" scoped>
.header {
 
  color: rgb(253, 250, 250);
}
</style>