var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"res-container ma-2"},_vm._l((_vm.items),function(item,index){return _c('div',_vm._g({key:index,staticClass:"res-column bg pa-10 my-2",class:item.class,style:([
        {
          'background-image':
            item.gradiant +
            ', url(' +
            require('@/assets/imgs/' + item.imgNmae) +
            ')',
        },
      ])},
        item.isLast == true
          ? { mouseover: _vm.rightHover, mouseout: _vm.rightOut }
          : {}),[_c('v-row',{staticClass:"content-row",staticStyle:{"height":"100%"},attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticClass:"l-text-col",attrs:{"cols":"12"}},[_c('div',{staticClass:"titl"},[_vm._v(_vm._s(item.title))]),_c('hr',{staticClass:"text-left"})]),_c('v-col',{staticClass:"r-text-col",attrs:{"cols":"12"}},[_c('div',{staticClass:"text"},[_vm._v(_vm._s(item.text))])])],1)],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }