<template>
  <OneDirectionSlider :items="this.items">
    <template v-slot:header> <div class="mb-4">Client Endorsements</div> </template>
    <template v-slot:subHeader>
      <h4 class="mt-4">Here’s what some of our client’s have to say about our services.</h4>
    </template>
    <template v-slot:content>
      <SplideSlide v-for="item,i in items" :key="item.title + i">
        <v-card align="center" class="pa-5 pt-10 mb-2 end-card">
          <v-row justify="start">
            <v-col justify="start" align="start">
              <v-icon class="ml-5" color="white">fas fa-quote-left</v-icon>
            </v-col>
          </v-row>

          <v-card-subtitle
            class="mt-5"
            style="
              min-height: 120px !important;
              color: white !important;
              font-size: 16px !important;
            "
            >{{ item.desc }}</v-card-subtitle
          >
          <v-row>
            <v-col cols="4"></v-col>
            <v-col>
              <v-card-title class="justify-center" style="color: white">{{
                item.title
              }}</v-card-title>
              <v-card-subtitle style="color: white">{{
                item.subtitle
              }}</v-card-subtitle>
            </v-col>
          </v-row>
        </v-card>
      </SplideSlide>
    </template>
  </OneDirectionSlider>
</template>
<script>
import { SplideSlide } from "@splidejs/vue-splide";
import OneDirectionSlider from "@/components/global/OneDirectionSlider.vue";
import "@splidejs/splide/dist/css/splide.min.css";
export default {
  created() {
    const data = require("@/assets/endrosments.json");
    this.items = data;
  
  },
  data() {
    return {
      items: []
    };
  },
  components: {
    SplideSlide,
    OneDirectionSlider,
  },
};
</script>

<style scoped lang="scss">
.end-card {
  background-color: #356f94;
  //clip-path: polygon(9% 0, 89% 0, 100% 19%, 100% 100%, 0 100%, 1% 19%);
  clip-path: polygon(12% 0, 88% 0, 100% 18%, 100% 100%, 0 100%, 0% 18%);
  //border: 3px solid #1f628e;
  // border-top-left-radius: 25% !important;
  // border-bottom-right-radius: 8% !important;
  // border-bottom-left-radius: 8% !important;
  // border-top-right-radius: 7% !important;

  //  border-top-right-radius: 15% !important;
  //  border-top-left-radius: 15% !important;
}
</style>

