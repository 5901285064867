<template>
  <v-hover>
    <template v-slot:default="{ hover }">
      <!-- <v-avatar size="300px" > -->
      <v-card
        flat
        striped
        exact
        :to="{ name: 'Details', params: { name: name } }"
      >
        <!-- <template slot="progress">
          <v-progress-circular
            :size="70"
            :width="7"
            color="purple"
            indeterminate
          ></v-progress-circular>
        </template> -->
        <ImageCard
          :imageName="
            !hover && imgName.includes('Professional')? imgName : imgName.replace('Professional', 'Prop')
          "
          :alt="name"
          parentFolder="team"
          :minHeight_c="-1"
          :maxHeight_c="-1"
        />
        <v-row>
          <v-col>
            <v-card-subtitle class="justify-center t-name">
              {{ name }}
            </v-card-subtitle>
            <v-card-text :style="!$vuetify.breakpoint.smAndDown?{'min-height':'70px !important'}:{'min-height':'70px !important'}">
              {{ job }}
            </v-card-text>
          </v-col>
        </v-row>

        <ImageCard />
        <!-- <v-expand-transition>
          <v-overlay v-if="hover" absolute height="30px">
            
             <v-card-title>
              {{ name }}
            </v-card-title>
            <v-card-text>
              {{ job }}
            </v-card-text> 
          </v-overlay>
        </v-expand-transition> -->
      </v-card>
      <!-- </v-avatar> -->
    </template>
  </v-hover>
</template>

<script>
import ImageCard from "@/components/global/ImageCard";
export default {
  components: { ImageCard },
  data: () => ({
    overlay: false,
    loading: false,
  }),
  props: {
    name: {
      type: String,
    },
    job: {
      type: String,
    },
    imgName: {
      type: String,
    },
  },
};
</script>

<style scoped>
.t-name{
  font-size: 20px !important;
}
</style>