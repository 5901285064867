<template>
  <v-main>
    <v-content class="content">
      <v-container fluid>
        <v-row style="background-color: #3a3a3a" align="center" class="pa-16">
          <v-col align="center">
            <h1 class="px-10 white--text pt-0">Helping Secure Your Sensitive Data</h1>
          </v-col>
        </v-row>
        <v-row justify="space-around">
          <v-col cols="8" class="col-xs-10 col-sm-10 col-md-10 col-lg-8">
            <v-img
              src="img/blogs/MSP-BLog.jpg"
              aspect-ratio="1.7"
              alt="MSP-BLog"
            ></v-img>
          </v-col>
        </v-row>
        <v-row justify="space-around">
          <v-col cols="8" class="col-xs-10 col-sm-10 col-md-10 col-lg-8">
            <article>
              <div class="entry-content">
                <div class="entry-content-inner">
                  
                  <h2>
                    Two-Factor Authentication and Why you should be using it.
                  </h2>
                  <br>
                  <p>
                    Privileged information and confidentiality are synonymous
                    with the legal industry. This makes anyone in that industry
                    a prime target for cybersecurity attacks. For law firms,
                    attorneys and corporate counsel professionals, a data breach
                    can be disastrous for the organization. Attorneys have an
                    ethical obligation to protect the client’s data from a
                    breach.
                    <a
                      href="https://www.americanbar.org/products/ecd/ebk/418618662/"
                      target="_blank"
                      rel="noreferrer noopener"
                      >ABA’s 2021 Legal Technology Survey</a
                    >
                    reports 25% of the respondents stated that their law firm
                    had “at some time” had a breach. Considering this is the
                    percentage of those who responded, the liability firms are
                    facing is staggering.
                  </p>

                  <p>
                    All too often these breaches are the result of password
                    related hacks, such as stolen credentials or reused
                    passwords. With more and more people accessing sensitive
                    information remotely, the threat to privacy and security is
                    only growing. So great is the risk around password related
                    cyber-attacks, that multi-factor authentication is becoming
                    mandatory in order to maintain liability insurance. Law
                    firms and other companies with access to confidential
                    information can better secure their systems with simple
                    tools such as two-factor authentication (2FA). While
                    additional defense measure should also be put into place,
                    2FA is one of the first lines of defense you can take and
                    has been deemed a best practice for all firms. Most
                    liability carriers are requiring 2FA for renewal.
                  </p>

                  <p>
                    <strong>What is Two-Factor Authentication? </strong>
                  </p>
                  <p>
                    Two-factor authentication (2FA) is a security method in
                    which the user is required to provide two different types of
                    identifications to verify access to information. The types
                    of identification can vary and usually involves a password
                    at the first level and a second level such as a code that is
                    sent to verified mobile device or email account. Other
                    second level verifications can include biometrics such as a
                    fingerprint or facial scan. This operation is intended to
                    better protect both the user’s credentials and data they
                    have access to, and adds an additional layer of security,
                    making it more difficult for cybercriminals to gain access
                    via phishing schemes or repeated passwords that were part of
                    a prior data breach.
                  </p>

                  <p>
                    <strong
                      >What Is Involved in Enabling Two-Factor Authentication?
                    </strong>
                  </p>

                  <p>
                    Two-factor authentication delivers a strong level of
                    security for your firm’s data and can be easily applied to
                    most online accounts and applications by simply going to
                    security setting and enabling it. This can take some time,
                    and let’s face it, going through the steps of 2FA for every
                    online application and account you have can become
                    frustrating. As frustrating as it may be, make no mistake
                    the process of letting your clients know their information
                    has been breached and suffering the costly loss of time,
                    reputation and revenue is infinitely worse. There are
                    several applications you can use to prevent some of this
                    frustration, while maintaining the defense of 2FA. Both
                    Microsoft and Google have authentication applications you
                    can use to make the process of keeping track your two-factor
                    authentications. These applications make the process much
                    easier, while giving you peace of mind you have taken a
                    necessary step for securing data.
                  </p>

                  <p>
                    Learn how our team of technology experts can help you with
                    two-factor authentication and more.
                  </p>
                </div>
                <div>
                  <div class="my-2">
                    <v-btn
                      color="#a81e2d"
                      dark
                      large
                      href="https://share.hsforms.com/1cb9emC-9Q96BX3_Yuu29RA3gv9a"
                      target="_blank"
                    >
                      Schedule A Free Consultation Today
                    </v-btn>
                  </div>
                </div>
              </div>
            </article>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </v-main>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
article .entry-content {
  padding-top: 20px;
}
.entry-content-inner {
  width: 100%;
  text-align: justify;
  line-height: 23px;
}
p {
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
}
p strong {
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
}
.contact {
  font-size: 22px !important;
  font-weight: 700;
}
.content {
  background: #f8f9f9;
}
</style>