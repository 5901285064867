import Vue from 'vue'
Vue.mixin({
    methods: {
        goToId(id) {
            document.getElementById(id).scrollIntoView();
        },
        openLink(link) {
            window.open(link, "blank");
          },
          copyToClipboard(text) {
            navigator.clipboard.writeText(text);
          }
    }
})